import React, { useState } from 'react';

const AgregarSolicitudInterna = ({ puertos, cerrar }) => {
    const [formData, setFormData] = useState({
        codigo: '',
        id_puerto_destino: '',
        etd: '',
        cantidad: '',
        requerimientos: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleChangeUpperCase = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.toUpperCase() });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        try {
            const response = await fetch('https://api.dvstarlab.com/addsolicitudinterna', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(formData)
            });
            if (!response.ok) throw new Error('Error al agregar la solicitud interna');
            alert('Solicitud interna agregada exitosamente');
            cerrar();
        } catch (error) {
            console.error('Error:', error);
            alert('No se pudo agregar la solicitud interna');
        }
    };

    return (
        <div className="modal show" style={{ display: 'block' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Agregar Solicitud de Booking</h5>
                        <button type="button" className="btn-close" onClick={cerrar}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Código</label>
                                <input type="text" className="form-control" name="codigo" onChange={handleChangeUpperCase} value={formData.codigo} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Puerto Destino</label>
                                <select
                                    className="form-select"
                                    name="id_puerto_destino"
                                    onChange={handleChange}
                                    value={formData.id_puerto_destino}
                                >
                                    <option value="">Seleccione un Puerto</option>
                                    {puertos
                                        .filter((puerto) => puerto.indicador === 2 || puerto.indicador === 3) // Filtra puertos destino
                                        .map((item) => (
                                            <option key={item.id_puerto} value={item.id_puerto}>
                                                {item.nombre}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">ETD</label>
                                <input type="date" className="form-control" name="etd" onChange={handleChange} value={formData.etd} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Cantidad de Contenedores</label>
                                <input type="number" className="form-control" name="cantidad" onChange={handleChange} value={formData.cantidad} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Requerimientos</label>
                                <textarea className="form-control" name="requerimientos" onChange={handleChange} value={formData.requerimientos}></textarea>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                                <button type="button" className="btn btn-secondary" onClick={cerrar}>Cancelar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AgregarSolicitudInterna;
        
