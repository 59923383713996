import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import { FaRegCopy } from "react-icons/fa";
import { FaFileExcel } from "react-icons/fa";
import AgregarSolicitud from './AgregarSolicitud';
import EditarSolicitud from './EditarSolicitud';
import ReplicarSolicitud from './ReplicarSolicitud';
import GenerarCorreo from './GenerarCorreo';
import * as XLSX from "xlsx";
import { Modal, Button, Spinner, Badge } from 'react-bootstrap';
import { toast } from 'react-toastify';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
};

const SolicitudesBooking = () => {
    const [loading, setLoading] = useState(true);
    const [dataSolicitudes, setDataSolicitudes] = useState([]);
    const [dataPuertos, setDataPuertos] = useState([]);
    const [dataFFW, setDataFFW] = useState([]);
    const [dataCarrier, setDataCarrier] = useState([]);
    const [dataSolicitudesInternas, setDataSolicitudesInternas] = useState([]);
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [showReplicar, setShowReplicar] = useState(false);
    const [showGenerarCorreo, setShowGenerarCorreo] = useState(false);
    const [solicitudReplicar, setSolicitudReplicar] = useState(null);
    const [showModalSolicitudesInternas, setShowModalSolicitudesInternas] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filterFFW, setFilterFFW] = useState(''); // Filtro por FFW
    const [filterCarrier, setFilterCarrier] = useState(''); // Filtro por Carrier
    const [filterEstado, setFilterEstado] = useState(''); // Filtro por Estado
    const [filterMonth, setFilterMonth] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [apoyo, setApoyo] = useState(undefined);
    const [principal, setPrincipal] = useState(undefined);
    const firstRender = useRef(true);

    const fetchData = async (page = currentPage, rowsPerPage = perPage) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://api.dvstarlab.com/solicitudesbooking`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) throw new Error('Error fetching Solicitudes');
            const { data, total } = await response.json();
            setDataSolicitudes(data);
            setTotalItems(total);
            setPrincipal(true);
        } catch (error) {
            console.error('Error fetching data:', error);
            setPrincipal(false);
            toast.error(`Solicitudes no cargadas: ${error.message}`, { toastId: 'error-principal' });
        }
    };

    const fetchApoyo = async () => {
        const token = localStorage.getItem('token');
        try {
            const responses = await Promise.all([
                fetch('https://api.dvstarlab.com/puertos', { headers: { 'Authorization': `Bearer ${token}` } }),
                fetch('https://api.dvstarlab.com/ffw', { headers: { 'Authorization': `Bearer ${token}` } }),
                fetch('https://api.dvstarlab.com/carriers', { headers: { 'Authorization': `Bearer ${token}` } }),
                fetch('https://api.dvstarlab.com/solicitudesinternas', { headers: { 'Authorization': `Bearer ${token}` } }),
            ]);

            const [puertos, ffw, carriers, solicitudesInternas] = await Promise.all(responses.map(res => {
                if (!res.ok) throw new Error('Error fetching data');
                return res.json();
            }));

            setDataPuertos(puertos);
            setDataFFW(ffw);
            setDataCarrier(carriers);
            setDataSolicitudesInternas(solicitudesInternas.data || []);
            setApoyo(true);
        } catch (error) {
            console.error('Error en fetchApoyo:', error.message);
            setApoyo(false);
            toast.error(`Datos de apoyo no cargados: ${error.message}`, { toastId: 'error-apoyo' });
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            setLoading(true);
            fetchData();
            fetchApoyo();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        if (principal !== undefined && apoyo !== undefined) {
            setLoading(!principal || !apoyo);
            if (principal && apoyo) {
                toast.success('Datos cargados correctamente.', { toastId: 'success' });
            } else {
                if (!principal) toast.error('Error en solicitudes booking.', { toastId: 'error-principal' });
                if (!apoyo) toast.error('Error en datos de apoyo.', { toastId: 'error-apoyo' });
            }
        }
    }, [principal, apoyo]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(page, perPage);
    };

    useEffect(() => {
        const filtered = dataSolicitudes?.filter((row) => {
            const matchesText = row.codigo?.toLowerCase().includes(filterText.toLowerCase());
            const matchesFFW = filterFFW ? row.id_ffw === parseInt(filterFFW) : true;
            const matchesCarrier = filterCarrier ? row.id_carrier === parseInt(filterCarrier) : true;
            const matchesEstado = filterEstado ? row.estado === parseInt(filterEstado) : true;
            const matchesMonth = filterMonth
                ? new Date(row.fecha_creacion).getUTCMonth() + 1 === parseInt(filterMonth)
                : true;
            const matchesYear = filterYear
                ? new Date(row.fecha_creacion).getUTCFullYear() === parseInt(filterYear)
                : true;

            return matchesText && matchesFFW && matchesCarrier && matchesEstado && matchesMonth && matchesYear;
        });
        setFilteredData(filtered);
    }, [dataSolicitudes, filterText, filterFFW, filterCarrier, filterEstado, filterMonth, filterYear]);

    const columns = [
        {
            name: 'Código',
            selector: row => row.codigo,
            width: '250px',
        },
        {
            name: 'Nave',
            selector: row => row.nave,
        },
        {
            name: 'N° Contenedores',
            selector: row => row.cantidad,
            width: '150px'
        },
        {
            name: 'ETD',
            selector: row => formatDate(row.etd),
        },
        {
            name: 'Origen',
            selector: row => dataPuertos.find(puerto => puerto.id_puerto === row.id_puerto_origen)?.nombre || 'Desconocido',
        },
        {
            name: 'Destino',
            selector: row => dataPuertos.find(puerto => puerto.id_puerto === row.id_puerto_destino)?.nombre || 'Desconocido',
        },
        {
            name: 'FFW',
            selector: row => row.id_ffw ? dataFFW.find(ffw => ffw.id_ffw === row.id_ffw)?.nombre : 'Sin FFW',
        },
        {
            name: 'Carrier',
            selector: row => dataCarrier.find(carrier => carrier.id_carrier === row.id_carrier)?.nombre || 'Desconocido',
        },
        {
            name: 'Fecha Creación',
            selector: row => row.fecha_creacion ? formatDate(row.fecha_creacion) : '',
        },
        {
            name: 'Estado',
            selector: row => row.estado === 0 ? 'Por Solicitar' : row.estado === 1 ? 'Solicitado' : row.estado === 2 ? 'Confirmado' : row.estado === 3 ? 'Cancelado' : 'Indefinido',
        },
        {
            name: 'Solicitud Interna',
            selector: row => row.solicitud_interna ? row.solicitud_interna.codigo : 'Sin Solicitud Interna',
        },
        {
            name: 'Acciones',
            cell: row => (
                <div>
                    <button className='btn btn-outline-primary' onClick={() => handleEditar(row)}><LuPencilLine /></button>
                    <button
                        className='btn btn-outline-warning'
                        onClick={() => {
                            handleReplicar(row);
                        }}
                    >
                        <FaRegCopy />
                    </button>
                    {row.estado === 0 &&
                        <button
                            className='btn btn-outline-danger'
                            onClick={() => {
                                if (window.confirm('¿Estás seguro de eliminar esta solicitud?')) {
                                    handleEliminar(row.id_solicitud);
                                }
                            }}
                        >
                            <IoTrashOutline />
                        </button>
                    }
                </div>
            ),
            ignoreRowClick: true,
        }
    ];

    const exportToExcel = (solicitudes) => {
        
        // Preparar los datos para el Excel
        const preparedData = solicitudes.map((solicitud) => ({
            Invoice: "", // Campo vacío
            NumberBL: "", // Campo vacío
            Requerimiento: solicitud.codigo || "", // Campo amarillo
            Booking: "TBD",
            EstadoBKN: "OK",
            Comentarios: "",
            FW: solicitud.id_ffw ? dataFFW.find(f => f.id_ffw === solicitud.id_ffw)?.nombre || '' : '',
            Carrier: solicitud.id_carrier ? dataCarrier.find(c => c.id_carrier === solicitud.id_carrier)?.nombre || '' : '',
            LCER: '',
            FTO: solicitud.ftorigen || '',
            FTD: solicitud.ftdestino || '',
            Coleta: '',
            Estatus: '',
            MAPADoc: '',
            DLDraft: '',
            Nave: solicitud.nave || '',
            DLCarga: '',
            MAPAFisico: '',
            x: '',
            ETD: solicitud.etd || '',
            EstatusBL: '',
            POL: solicitud.id_puerto_origen
                ? dataPuertos.find(p => p.id_puerto === solicitud.id_puerto_origen)?.nombre || ''
                : '',
            POD: solicitud.id_puerto_destino
                ? dataPuertos.find(p => p.id_puerto === solicitud.id_puerto_destino)?.nombre || ''
                : '',
            Proveedor: '',
            Cantidad: solicitud.cantidad || '',
            Apoyo: '',
            MAPA: '',
            Observaciones: solicitud.requerimientos || '',
            Producto: '',
            Contrato: '',
            Cliente: '',
            Categoria: '',
            LC: '',
            FechaEnvio: '',
            ColetavsDraft: '',
            EnvioDraftvsDraft: '',
            NSemana: '',
            NMes: '',
            FreteUSD: solicitud.flete || '',
            Contrato: '',
            m3: '',
        }));
    
        // Crear la hoja de cálculo y el libro de trabajo
        const worksheet = XLSX.utils.json_to_sheet(preparedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Solicitudes");
    
        // Exportar el archivo Excel
        XLSX.writeFile(workbook, "SolicitudesBooking.xlsx");
    };

    const handleAgregar = () => setShowAgregar(true);

    const handleCloseAgregar = () => {
        setShowAgregar(false);
        fetchData();
    };

    const handleShowModalSolicitudesInternas = () => setShowModalSolicitudesInternas(true);
    const handleCloseModalSolicitudesInternas = () => setShowModalSolicitudesInternas(false);

    const handleGenerarCorreo = () => setShowGenerarCorreo(true);

    const handleCloseGenerarCorreo = () => {
        setShowGenerarCorreo(false)
        fetchData();
    };

    const handleEditar = (solicitud) => {
        setSelected(solicitud);
        setShowEditar(true);
    };

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false);
        fetchData();
    };

    const handleReplicar = async (solicitud) => {
        setShowReplicar(true);
        setSolicitudReplicar(solicitud);
    };

    const handleCloseReplicar = () => {
        setShowReplicar(false);
        setSelected(null);
        fetchData();
    }

    const handleEliminar = async (id) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://api.dvstarlab.com/deletesolicitudbooking/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Error eliminando solicitud');
            alert('Solicitud eliminada exitosamente');
            fetchData();
        } catch (error) {
            console.error('Error:', error);
            alert('No se pudo eliminar la solicitud');
        }
    };

    const pendientesCount = dataSolicitudesInternas.filter(solicitud => solicitud.estado != 1).length;

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>Solicitudes de Booking</h3>
                </div>
                <div className='card-body'>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status" />
                            <span className="sr-only">Cargando...</span>
                        </div>
                    ) : (
                        <>
                            {/* Filtros */}
                            <div className="row mb-3">
                                <input
                                    type="text"
                                    placeholder="Buscar por Código..."
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                    className="form-control"
                                />
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <select
                                        className="form-select"
                                        value={filterFFW}
                                        onChange={(e) => setFilterFFW(e.target.value)}
                                    >
                                        <option value="">Todos los FFW</option>
                                        {dataFFW.map((ffw) => (
                                            <option key={ffw.id_ffw} value={ffw.id_ffw}>
                                                {ffw.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <select
                                        className="form-select"
                                        value={filterCarrier}
                                        onChange={(e) => setFilterCarrier(e.target.value)}
                                    >
                                        <option value="">Todos los Carrier</option>
                                        {dataCarrier.map((carrier) => (
                                            <option key={carrier.id_carrier} value={carrier.id_carrier}>
                                                {carrier.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <select
                                        className="form-select"
                                        value={filterMonth}
                                        onChange={(e) => setFilterMonth(e.target.value)}
                                    >
                                        <option value="">Mes</option>
                                        {[...Array(12).keys()].map(month => (
                                            <option key={month + 1} value={month + 1}>
                                                {new Date(0, month).toLocaleString('default', { month: 'long' })}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <select
                                        className="form-select"
                                        value={filterYear}
                                        onChange={(e) => setFilterYear(e.target.value)}
                                    >
                                        <option value="">Año</option>
                                        {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="btn-group" role="group" aria-label="Estado de Solicitudes">
                                        {[
                                            { value: '', label: 'Todos', color: 'secondary' },
                                            { value: '0', label: 'Por Solicitar', color: 'warning' },
                                            { value: '1', label: 'Solicitado', color: 'primary' },
                                            { value: '2', label: 'Confirmado', color: 'success' },
                                            { value: '3', label: 'Cancelado', color: 'danger' }
                                        ].map(({ value, label, color }) => {
                                            // Calcular número de solicitudes para cada estado considerando los otros filtros
                                            const filteredCount = dataSolicitudes.filter((row) => {
                                                const matchesText = row.codigo?.toLowerCase().includes(filterText.toLowerCase());
                                                const matchesFFW = filterFFW ? row.id_ffw === parseInt(filterFFW) : true;
                                                const matchesCarrier = filterCarrier ? row.id_carrier === parseInt(filterCarrier) : true;
                                                const matchesEstado = value === '' ? true : row.estado === parseInt(value);

                                                return matchesText && matchesFFW && matchesCarrier && matchesEstado;
                                            }).length;

                                            return (
                                                <button
                                                    key={value}
                                                    type="button"
                                                    className={`btn btn-outline-${color} ${filterEstado === value ? 'active' : ''}`}
                                                    onClick={() => setFilterEstado(value)}
                                                >
                                                    {label} ({filteredCount})
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3 d-flex justify-content-between">
                                <div>
                                    <button className="btn btn-outline-primary" onClick={handleAgregar}>Agregar Solicitud</button>
                                    <button className="btn btn-outline-success" onClick={handleGenerarCorreo}>Generar Correo</button>
                                    <button className="btn btn-outline-success" onClick={() => exportToExcel(filteredData)}>
                                        <FaFileExcel />
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="btn btn-outline-primary position-relative"
                                        onClick={handleShowModalSolicitudesInternas}
                                    >
                                        Solicitudes Pendientes
                                        {pendientesCount > 0 && (
                                            <Badge bg="danger" className="position-absolute top-0 start-100 translate-middle">
                                                {pendientesCount}
                                            </Badge>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                noHeader
                                pagination
                                paginationServer
                                highlightOnHover
                                paginationTotalRows={totalItems}
                                onChangePage={handlePageChange}
                            />
                        </>
                    )}
                </div>
            </div>

            {showAgregar && <AgregarSolicitud solicitudes={dataSolicitudes} puertos={dataPuertos} ffw={dataFFW} carriers={dataCarrier} solicitudesInternas={dataSolicitudesInternas} cerrar={handleCloseAgregar} />}
            {showReplicar && <ReplicarSolicitud puertos={dataPuertos} ffw={dataFFW} carriers={dataCarrier} solicitudesInternas={dataSolicitudesInternas} solicitud={solicitudReplicar}  cerrar={handleCloseReplicar} />}
            {selected && showEditar && <EditarSolicitud solicitud={selected} puertos={dataPuertos} ffw={dataFFW} carriers={dataCarrier} solicitudesInternas={dataSolicitudesInternas} cerrar={handleCloseEditar} />}
            {showGenerarCorreo && <GenerarCorreo solicitudes={dataSolicitudes} puertos={dataPuertos} ffws={dataFFW} carriers={dataCarrier} cerrar={handleCloseGenerarCorreo} />}
                    <Modal show={showModalSolicitudesInternas} onHide={handleCloseModalSolicitudesInternas}>
                        <Modal.Header closeButton>
                            <Modal.Title>Solicitudes Internas Pendientes</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {pendientesCount > 0 ? (
                                <ul>
                                    {dataSolicitudesInternas
                                        .filter(solicitud => solicitud.estado != 1)
                                        .map(solicitud => {
                                            const puerto = dataPuertos.find(p => p.id_puerto === solicitud.id_puerto_destino)
                                            if(puerto){
                                                return(
                                                    <li key={solicitud.id_solicitudinterna}>
                                                        {solicitud.codigo} - {puerto.nombre}  
                                                    </li>
                                                )
                                            }
                                    })}
                                </ul>
                            ) : (
                                <p>No hay solicitudes internas pendientes.</p>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModalSolicitudesInternas}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>
        </div>
    );
};

export default SolicitudesBooking;
