import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
};

const getSaludo = () => {
    const horaActual = new Date().getHours();
    console.log(horaActual);
    return horaActual < 12 ? 'Bom dia' : 'Boa tarde';
};

const GenerarCorreo = ({ solicitudes, puertos, ffws, carriers, cerrar }) => {
    const [FFW, setFFW] = useState('');
    const [listaSolicitudes, setListaSolicitudes] = useState([]);
    const [solicitudSeleccionada, setSolicitudSeleccionada] = useState('');
    const [correoContenido, setCorreoContenido] = useState('');

    const handleChangeFFW = (e) => {
        setListaSolicitudes([]); // Reinicia la lista de solicitudes seleccionadas
        setSolicitudSeleccionada('');
        setFFW(e.target.value);
    };

    const handleAgregarSolicitud = () => {
        const solicitud = solicitudes.find(s => s.id_solicitud === parseInt(solicitudSeleccionada));
        if (solicitud && !listaSolicitudes.some(s => s.id_solicitud === solicitud.id_solicitud)) {
            setListaSolicitudes([...listaSolicitudes, solicitud]);
        }
    };

    const handleEliminarSolicitud = (idSolicitud) => {
        setListaSolicitudes(listaSolicitudes.filter(s => s.id_solicitud !== idSolicitud));
    };

    const handleGenerarCorreo = () => {
        if (!FFW) {
            alert('Seleccione un FFW antes de generar el correo.');
            return;
        }

        if (listaSolicitudes.length === 0) {
            alert('Seleccione al menos una solicitud para incluir en el correo.');
            return;
        }

        const ffwSeleccionado = ffws.find(ffw => ffw.id_ffw === parseInt(FFW));
        const saludo = getSaludo();
        const cuerpoCorreo = listaSolicitudes.map((solicitud, index) => {
            const origen = puertos.find(p => p.id_puerto === solicitud.id_puerto_origen)?.nombre || 'Desconocido';
            const destino = puertos.find(p => p.id_puerto === solicitud.id_puerto_destino)?.nombre || 'Desconocido';
            const carrier = carriers.find(c => c.id_carrier === solicitud.id_carrier)?.nombre || 'Desconocido';

            return `
ETD: ${formatDate(solicitud.etd)}
REF: ${solicitud.codigo}
Quantidade: ${solicitud.cantidad}x40HC Supertestado
Cargo Weight: ${solicitud.cargo_weight}
POL: ${origen}
POD: ${destino}
Armador: ${carrier}
Frete: USD $${solicitud.flete}.
Free time Brasil: ${solicitud.ftorigen}
Free time destino: ${solicitud.ftdestino}

${solicitud.requerimientos}
            `;
        }).join('\n');

        setCorreoContenido(`
${saludo} ${ffwSeleccionado.nombre_contacto[0]},

Tudo bem?

${cuerpoCorreo}
Ficamos no aguardo dos BKs confirmados.

Muito obrigado e boa semana.

Saludos,
        `);
    };

    const handleCopiarCorreo = () => {
        navigator.clipboard.writeText(correoContenido).then(() => {
            alert('Correo copiado al portapapeles.');
        }).catch(() => {
            alert('Error al copiar el correo.');
        });
    };

    const handleEnviarCorreo = async () => {
        if (listaSolicitudes.length === 0) {
            alert('No hay solicitudes seleccionadas para enviar.');
            return;
        }
    
        const ffwSeleccionado = ffws.find(ffw => ffw.id_ffw === parseInt(FFW));
        const email = ffwSeleccionado?.correo_contacto[0] || 'destinatario@example.com'; // Email por defecto
        const subject = encodeURIComponent('Solicitações e bookings');
        const body = encodeURIComponent(correoContenido);
    
        // Abrir cliente de correo
        const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
        window.location.href = mailtoLink;
    
        const token = localStorage.getItem('token');
        try {
            const failedRequests = []; // Para almacenar solicitudes fallidas
            for (const solicitud of listaSolicitudes) {
                const response = await fetch(
                    `https://api.dvstarlab.com/editsolicitudbooking/${solicitud.id_solicitud}`,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({ estado: 1 }) // Cambiar estado a 1
                    }
                );
    
                if (!response.ok) {
                    failedRequests.push(solicitud.id_solicitud);
                }
            }
            if (failedRequests.length === 0) {
                alert('Correo enviado y estado de solicitudes actualizado.');
                cerrar(); // Cerrar modal después de enviar
            } else {
                alert(
                    `El correo fue enviado, pero hubo problemas actualizando estas solicitudes: ${failedRequests.join(
                        ', '
                    )}. Por favor, verifica manualmente.`
                );
            }
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            alert('Hubo un problema al actualizar las solicitudes. Por favor verifica manualmente.');
        }
    };

    return (
        <div className="modal show" style={{ display: 'block' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Generar Correo</h5>
                        <button type="button" className="btn-close" onClick={cerrar}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label">Seleccione FFW</label>
                            <select className="form-select" value={FFW} onChange={handleChangeFFW}>
                                <option value="">Seleccione un FFW</option>
                                {ffws.map(ffw => (
                                    <option key={ffw.id_ffw} value={ffw.id_ffw}>
                                        {ffw.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {FFW && (
                            <>
                                <div className="mb-3">
                                    <label className="form-label">Seleccione Solicitud</label>
                                    <select
                                        className="form-select"
                                        value={solicitudSeleccionada}
                                        onChange={(e) => setSolicitudSeleccionada(e.target.value)}
                                    >
                                        <option value="">Seleccione una Solicitud</option>
                                        {solicitudes
                                            .filter(s => s.estado === 0)
                                            .filter(s => s.id_ffw === parseInt(FFW) && !listaSolicitudes.some(agregada => agregada.id_solicitud === s.id_solicitud))
                                            .map(solicitud => (
                                                <option key={solicitud.id_solicitud} value={solicitud.id_solicitud}>
                                                    {solicitud.codigo}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        onClick={handleAgregarSolicitud}
                                    >
                                        Agregar a la Lista
                                    </button>
                                </div>
                            </>
                        )}

                        <div className="mb-3">
                            <h5>Solicitudes en el Correo</h5>
                            <DataTable
                                columns={[
                                    {
                                        name: 'Codigo',
                                        selector: row => row.codigo,
                                    },
                                    {
                                        name: 'ETD',
                                        selector: row => formatDate(row.etd),
                                    },
                                    {
                                        name: 'Eliminar',
                                        cell: row => (
                                            <button
                                                className="btn btn-outline-danger"
                                                onClick={() => handleEliminarSolicitud(row.id_solicitud)}
                                            >
                                                Eliminar
                                            </button>
                                        ),
                                        ignoreRowClick: true,
                                    },
                                ]}
                                data={listaSolicitudes}
                                noHeader
                                responsive
                                highlightOnHover
                            />
                        </div>

                        <div className="mb-3">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleGenerarCorreo}
                            >
                                Generar Correo
                            </button>
                        </div>

                        {correoContenido && (
                            <>
                                <div className="mb-3">
                                    <label className="form-label">Contenido del Correo</label>
                                    <textarea
                                        className="form-control"
                                        value={correoContenido}
                                        rows="10"
                                        readOnly
                                    ></textarea>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-secondary me-2"
                                    onClick={handleCopiarCorreo}
                                >
                                    Copiar Correo
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={handleEnviarCorreo}
                                >
                                    Enviar Correo
                                </button>
                            </>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={cerrar}>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenerarCorreo;
