import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import AgregarSolicitudInterna from './AgregarSolicitudInterna';
import { Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
};

const SubTablaSolicitudes = ({ solicitudInternaId }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchSolicitudesAsociadas = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`https://api.dvstarlab.com/solicitudesasociadas/${solicitudInternaId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (!response.ok) throw new Error('Error fetching solicitudes asociadas');
                const { data } = await response.json();
                setData(data);
            } catch (error) {
                console.error('Error fetching solicitudes asociadas:', error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSolicitudesAsociadas();
    }, [solicitudInternaId]);

    const columns = [
        { name: 'Código', selector: row => row.codigo },
        { name: 'ETD', selector: row => row.etd },
        { name: 'Cantidad', selector: row => row.cantidad },
        { name: 'Estado', selector: row => row.estado === 0 ? 'Por Solicitar' : row.estado === 1 ? 'Solicitado' : row.estado === 2 ? 'Confirmado' : row.estado === 3 ? 'Cancelado' : 'Indefinido'},
        { name: 'Fecha de Creación', selector: row => row.fecha_creacion },
    ];

    if (loading) {
        return <div>Cargando solicitudes asociadas...</div>;
    }

    if (data.length === 0) {
        return <div>No hay solicitudes asociadas.</div>;
    }

    return (
        <DataTable
            columns={columns}
            data={data}
            noHeader
            pagination={false}
            highlightOnHover
            responsive
        />
    );
};


const SolicitudesBookingInternas = () => {
    const [loading, setLoading] = useState(true);
    const [dataSolicitudesInternas, setDataSolicitudesInternas] = useState([]);
    const [dataPuertos, setDataPuertos] = useState([]);
    const [showAgregar, setShowAgregar] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [filterMonth, setFilterMonth] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [filterEstado, setFilterEstado] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [apoyo, setApoyo] = useState(undefined);
    const [principal, setPrincipal] = useState(undefined);
    const firstRender = useRef(true);

    const fetchData = async (page = currentPage, rowsPerPage = perPage) => {
        const token = localStorage.getItem('token');
        try {
            // Fetch Solicitudes Internas
            const responseSolicitudesInternas = await fetch('https://api.dvstarlab.com/solicitudesinternas', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!responseSolicitudesInternas.ok) throw new Error('Error fetching solicitudes internas');
            const { data, total } = await responseSolicitudesInternas.json();
            setDataSolicitudesInternas(data);
            setTotalItems(total);
            setPrincipal(true);
        } catch (error) {
            console.error('Error en fetchData:', error.message);
            setPrincipal(false); // Indica que la solicitud principal falló
            toast.error(`Solicitudes internas no cargadas: ${error.message}`, { toastId: 'error-principal' });
        }
    };

    const fetchApoyo = async () => {
        const token = localStorage.getItem('token');
        try {
            // Fetch Puertos
            const responsePuertos = await fetch('https://api.dvstarlab.com/puertos', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!responsePuertos.ok) throw new Error('Error fetching puertos');
            const puertos = await responsePuertos.json();
            setDataPuertos(puertos);
            setApoyo(true); // Indica que la solicitud de apoyo fue exitosa
        } catch (error) {
            console.error('Error en fetchApoyo:', error.message);
            setApoyo(false); // Indica que la solicitud de apoyo falló
            toast.error(`Puertos y solicitudes no cargadas: ${error.message}`, { toastId: 'error-apoyo' });
        }
    };

    useEffect(() => {
        if (firstRender.current) {
            setLoading(true);
            fetchData();
            fetchApoyo();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        if (principal !== undefined && apoyo !== undefined) {
            setLoading(!principal || !apoyo);
            if (principal && apoyo) {
                toast.success('Datos cargados correctamente.', { toastId: 'success' });
            }
        }
    }, [principal, apoyo]);

    useEffect(() => {
        console.log('Filtrando con:', { filterText, filterMonth, filterYear, filterEstado });
        const filtered = Array.isArray(dataSolicitudesInternas)
            ? dataSolicitudesInternas.filter(solicitud => {
                const matchesText = solicitud.codigo?.toLowerCase().includes(filterText.toLowerCase());
                const matchesMonth = filterMonth
                    ? new Date(solicitud.fecha_creacion).getUTCMonth() + 1 === parseInt(filterMonth)
                    : true;
                const matchesYear = filterYear
                    ? new Date(solicitud.fecha_creacion).getUTCFullYear() === parseInt(filterYear)
                    : true;
                const matchesEstado = filterEstado !== '' ? solicitud.estado === parseInt(filterEstado) : true;

                return matchesText && matchesMonth && matchesYear && matchesEstado;
            })
            : [];
        setFilteredData(filtered);
    }, [dataSolicitudesInternas, filterText, filterEstado, filterMonth, filterYear]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(page, perPage);
    };

    const columns = [
        {
            name: 'Código',
            selector: row => row.codigo,
            width: '200px',
        },
        {
            name: 'ETD',
            selector: row => formatDate(row.etd),
        },
        {
            name: 'Puerto Destino',
            selector: row => dataPuertos.find(p => p.id_puerto === row.id_puerto_destino)?.nombre || 'Desconocido',
        },
        {
            name: 'Cantidad',
            selector: row => row.cantidad,
        },
        { 
            name: 'Fecha creacion',
            selector: row => formatDate(row.fecha_creacion) 
        },
        {
            name:'estado',
            selector: row => row.estado === 1 ? 'Completado' : 'Pendiente',
        },
        {
            name: 'Acciones',
            cell: row => (
                <div>
                    <button className='btn btn-outline-primary' onClick={() => handleEditar(row)}><LuPencilLine /></button>
                    <button className='btn btn-outline-danger' onClick={() => handleEliminar(row.id_solicitudinterna)}><IoTrashOutline /></button>
                </div>
            ),
            button: true,
        },
    ];

    const handleAgregar = () => setShowAgregar(true);
    
    const handleCloseAgregar = () => {
        setShowAgregar(false);
        fetchData();
    };

    const handleEditar = (solicitud) => {
        setSelected(solicitud);
    };

    const handleEliminar = async (id) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`https://api.dvstarlab.com/deletesolicitudinterna/${id}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) throw new Error('Error eliminando solicitud interna');
            alert('Solicitud eliminada exitosamente');
            fetchData();
        } catch (error) {
            console.error('Error:', error);
            alert('No se pudo eliminar la solicitud interna');
        }
    };

    return (
        <div className='container-fluid'>
            <ToastContainer position="top-right" autoClose={3000} />
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title text-center'>Solicitudes Internas</h3>
                </div>
                <div className='card-body'>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status" />
                            <span className="sr-only">Cargando...</span>
                        </div>
                    ) : (
                        <>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    placeholder="Buscar por Código..."
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                    className="form-control"
                                />
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <select className="form-select" value={filterMonth} onChange={(e) => setFilterMonth(e.target.value)}>
                                        <option value="">Mes</option>
                                        {[...Array(12).keys()].map(month => (
                                            <option key={month + 1} value={month + 1}>
                                                {new Date(0, month).toLocaleString('default', { month: 'long' })}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <select className="form-select" value={filterYear} onChange={(e) => setFilterYear(e.target.value)}>
                                        <option value="">Año</option>
                                        {Array.from({ length: 5 }, (_, i) => new Date().getUTCFullYear() - i).map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="btn-group" role="group" aria-label="Estado de Solicitudes">
                                        {[
                                            { value: '', label: 'Todos', color: 'secondary' },
                                            { value: '0', label: 'Pendiente', color: 'warning' },
                                            { value: '1', label: 'Completado', color: 'success' },
                                        ].map(({ value, label, color }) => {
                                            const filteredCount = dataSolicitudesInternas.filter((row) => {
                                                const matchesText = row.codigo?.toLowerCase().includes(filterText.toLowerCase());
                                                const matchesMonth = filterMonth
                                                    ? new Date(row.fecha_creacion).getUTCMonth() + 1 === parseInt(filterMonth)
                                                    : true;
                                                const matchesYear = filterYear
                                                    ? new Date(row.fecha_creacion).getUTCFullYear() === parseInt(filterYear)
                                                    : true;
                                                const matchesEstado = value === '' ? true : row.estado === parseInt(value);

                                                return matchesText && matchesMonth && matchesYear && matchesEstado;
                                            }).length;

                                            return (
                                                <button
                                                    key={value}
                                                    type="button"
                                                    className={`btn btn-outline-${color} ${filterEstado === value ? 'active' : ''}`}
                                                    onClick={() => setFilterEstado(value)}
                                                >
                                                    {label} ({filteredCount})
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary mb-3" onClick={handleAgregar}>Agregar Solicitud</button>
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                pagination
                                paginationServer
                                paginationTotalRows={totalItems}
                                paginationDefaultPage={currentPage}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={(newPerPage) => {
                                    setPerPage(newPerPage);
                                    fetchData(1, newPerPage); // Reinicia a la primera página
                                }}
                                highlightOnHover
                                responsive
                                expandableRows
                                expandableRowsComponent={({ data }) => (
                                    <SubTablaSolicitudes solicitudInternaId={data.id_solicitudinterna} />
                                )}
                            />
                        </>
                    )}
                </div>
            </div>

            {showAgregar && <AgregarSolicitudInterna puertos={dataPuertos} cerrar={handleCloseAgregar} />}
        </div>
    );
};

export default SolicitudesBookingInternas;
