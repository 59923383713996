import React, { useState } from 'react';

const ReplicarSolicitud = ({ solicitud ,puertos, ffw, carriers, cerrar }) => {
    const [formData, setFormData] = useState({ ...solicitud, codigo: '', fecha_creacion: null });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        try {
            const response = await fetch('https://api.dvstarlab.com/addsolicitudbooking', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(formData)
            });
            if (!response.ok) throw new Error('Error al agregar la solicitud');
            alert('Solicitud agregada exitosamente');
            cerrar();
        } catch (error) {
            console.error('Error:', error);
            alert('No se pudo agregar la solicitud');
        }
    };

    const handleChangeLimited = (e, max) => {
        const { name, value } = e.target;
    
        // Asegurar que no supere el máximo permitido
        if (value <= max) {
            setFormData({
                ...formData,
                [name]: value,
            });
        } else {
            alert(`El valor máximo permitido es ${max} días.`);
        }
    };

    return (
        <div className="modal show" style={{ display: 'block' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Agregar Solicitud de Booking</h5>
                        <button type="button" className="btn-close" onClick={cerrar}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Código</label>
                                <input type="text" className="form-control" name="codigo" onChange={handleChange} value={formData.codigo} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">FFW</label>
                                <select
                                    className="form-select"
                                    name="id_ffw"
                                    onChange={(e) => {
                                        const value = e.target.value === "Sin FFW" ? null : e.target.value; // Convierte "Sin FFW" a null
                                        handleChange({ target: { name: "id_ffw", value } });
                                    }}
                                    value={formData.id_ffw || "Sin FFW"} // Maneja null como cadena vacía para el select
                                >
                                    <option value="Sin FFW">Sin FFW</option> {/* Configura el valor como "Sin FFW" para el select */}
                                    {ffw.map((item) => (
                                        <option key={item.id_ffw} value={item.id_ffw}>
                                            {item.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {formData.id_ffw === null || formData.id_ffw === "" ? (
                                <div className="mb-3">
                                    <label className="form-label">Carrier</label>
                                    <select
                                        className="form-select"
                                        name="id_carrier"
                                        onChange={handleChange}
                                        value={formData.id_carrier}
                                    >
                                        <option value="">Seleccione un Carrier</option>
                                        {carriers
                                            .filter((carrier) => carrier.tipo === 2) // Filtra los carriers tipo 2
                                            .map((item) => (
                                                <option key={item.id_carrier} value={item.id_carrier}>
                                                    {item.nombre}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            ) : (
                                <div className="mb-3">
                                    <label className="form-label">Carrier</label>
                                    <select
                                        className="form-select"
                                        name="id_carrier"
                                        onChange={handleChange}
                                        value={formData.id_carrier}
                                    >
                                        <option value="">Seleccione un Carrier</option>
                                        {carriers
                                            .filter((carrier) => carrier.tipo === 1) // Filtra los carriers tipo 1
                                            .map((item) => (
                                                <option key={item.id_carrier} value={item.id_carrier}>
                                                    {item.nombre}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            )}
                            <div className="mb-3">
                                <label className="form-label">Puerto Origen</label>
                                <select
                                    className="form-select"
                                    name="id_puerto_origen"
                                    onChange={handleChange}
                                    value={formData.id_puerto_origen}
                                >
                                    <option value="">Seleccione un Puerto</option>
                                    {puertos
                                        .filter((puerto) => puerto.indicador === 1 || puerto.indicador === 3) // Filtra puertos origen
                                        .map((item) => (
                                            <option key={item.id_puerto} value={item.id_puerto}>
                                                {item.nombre}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Puerto Destino</label>
                                <select
                                    className="form-select"
                                    name="id_puerto_destino"
                                    onChange={handleChange}
                                    value={formData.id_puerto_destino}
                                >
                                    <option value="">Seleccione un Puerto</option>
                                    {puertos
                                        .filter((puerto) => puerto.indicador === 2 || puerto.indicador === 3) // Filtra puertos destino
                                        .map((item) => (
                                            <option key={item.id_puerto} value={item.id_puerto}>
                                                {item.nombre}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Free Time Origen</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="ftorigen"
                                    onChange={(e) => handleChangeLimited(e, 35)}
                                    value={formData.ftorigen}
                                    placeholder="Días"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Free Time Destino</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="ftdestino"
                                    onChange={(e) => handleChangeLimited(e, 35)}
                                    value={formData.ftdestino}
                                    placeholder="Días"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Nave</label>
                                <input type="text" className="form-control" name="nave" onChange={handleChange} value={formData.nave} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">ETD</label>
                                <input type="date" className="form-control" name="etd" onChange={handleChange} value={formData.etd} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Tipo de Carga</label>
                                <select className="form-select" name="tipo_carga" onChange={handleChange} value={formData.tipo_carga}>
                                    <option value="">Seleccione un tipo de carga</option>
                                    <option value="40HV Supertestado">40HV Supertestado</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Cantidad de Contenedores</label>
                                <input type="number" className="form-control" name="cantidad" onChange={handleChange} value={formData.cantidad} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Peso de Carga</label>
                                <input type='text' className="form-control" name="cargo_weight" onChange={handleChange} value={formData.cargo_weight} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Flete</label>
                                <input type="number" step="0.01" className="form-control" name="flete" onChange={handleChange} value={formData.flete} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Requerimientos</label>
                                <textarea className="form-control" name="requerimientos" onChange={handleChange} value={formData.requerimientos}></textarea>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                                <button type="button" className="btn btn-secondary" onClick={cerrar}>Cancelar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReplicarSolicitud;
