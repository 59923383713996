import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

const AgregarCarrier = ({ cerrar }) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [nombre, setNombre] = useState('');
    const [tipo, setTipo] = useState(null);
    const [contactoNombres, setContactoNombres] = useState([]);
    const [contactoCorreos, setContactoCorreos] = useState([]);
    const [nuevoContacto, setNuevoContacto] = useState({ nombre: '', correo: ''});

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!nombre || tipo === null || (tipo === 2 && (!contactoNombres.length || !contactoCorreos.length))) {
            alert('Por favor, complete todos los campos');
            return;
        }

        setBotonDeshabilitado(true);

        const nuevoCarrier = {
            nombre,
            tipo,
            nombre_contacto: contactoNombres,
            correo_contacto: contactoCorreos,
        };

        const token = localStorage.getItem('token');

        fetch('https://api.dvstarlab.com/addcarrier', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(nuevoCarrier),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al agregar carrier');
            }
            return response.json();
        })
        .then(data => {
            console.log('Carrier agregado:', data);
            alert('Carrier agregado exitosamente');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        })
        .finally(() => {
            setBotonDeshabilitado(false);
        });
    }

    const handleAgregarContacto = () => {
        if(nuevoContacto.nombre && nuevoContacto.correo){
            setContactoNombres([...contactoNombres, nuevoContacto.nombre])
            setContactoCorreos([...contactoCorreos, nuevoContacto.correo])
        } else {
            alert('Todos los campos del contacto son requeridos.');
        }
    };

    const handleDeleteContacto = (index) => {
        setContactoNombres(contactoNombres.filter((_, i) => i !== index));
        setContactoCorreos(contactoCorreos.filter((_, i) => i !== index));
    };

    const handleRadioChange = (event) => {
        const selectedValue = parseInt(event.target.value);
        setTipo(selectedValue);
    };

    return(
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Agregar Carrier</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={1} checked={tipo === 1} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        FFW
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value={2} checked={tipo === 2} onChange={handleRadioChange}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Directa
                                    </label>
                                </div>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ingrese nombre"
                                  value={nombre}
                                  onChange={(e) => setNombre(e.target.value)}
                                  required
                                />
                            </div>
                            {tipo === 2 && (
                                <>
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Nombre Contacto</span>
                                    <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese nombre contacto"
                                    value={nuevoContacto.nombre}
                                    onChange={(e) => setNuevoContacto({ ...nuevoContacto, nombre: e.target.value })}
                                    />
                                    <span className='input-group-text'>Correo</span>
                                    <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Ingrese correo"
                                    value={nuevoContacto.correo}
                                    onChange={(e) => setNuevoContacto({ ...nuevoContacto, correo: e.target.value })}
                                    />
                                </div>
                                <button type="button" className="btn btn-outline-primary" onClick={handleAgregarContacto}>
                                    Agregar Contacto
                                </button>
                                <DataTable 
                                    columns={[
                                        { name: 'Nombre', selector: (_, index) => contactoNombres[index] },
                                        { name: 'Correo', selector: (_, index) => contactoCorreos[index] },
                                        {
                                            name: 'Eliminar',
                                            cell: (_, index) => (
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-danger"
                                                    onClick={() => handleDeleteContacto(index)}
                                                >
                                                    X
                                                </button>
                                            ),
                                            ignoreRowClick: true,
                                        },
                                    ]}
                                    data={contactoNombres.map((_, index) => index)} // Usa los índices como data
                                    highlightOnHover
                                    dense
                                    pagination={true}
                                />
                                </>
                            )}
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Agregar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AgregarCarrier