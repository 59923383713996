import React, { useState, useEffect, useRef } from 'react'
import DataTable from 'react-data-table-component'
import { IoTrashOutline } from "react-icons/io5";
import { LuPencilLine } from "react-icons/lu";
import AgregarFFW from './AgregarFFW';
import EditarFFW from './EditarFFW';

const FFW = () => {
    const [dataFFW, setDataFFW] = useState([]);
    const firstRender = useRef(true);
    const [showAgregar, setShowAgregar] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [selected, setSelected] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const fetchData = async () => {
        const token = localStorage.getItem('token');
        try {
            const ffwResponse = await fetch('https://api.dvstarlab.com/ffw', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (ffwResponse.status === 401) {
                throw new Error('Unauthorized');
            }
            const ffwData = await ffwResponse.json();
            setDataFFW(ffwData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, []);

    useEffect(() => {
        if (Array.isArray(dataFFW)) {
            const filtered = dataFFW.filter((row) =>
                Object.values(row).some((value) =>
                    value.toString().toLowerCase().includes(filterText.toLowerCase())
                )
            );
            setFilteredData(filtered);
        }
    }, [dataFFW, filterText]);

    const columns = [
        {
            name: 'ID',
            selector: row => row.id_ffw
        },
        {
            name: 'Nombre',
            selector: row => row.nombre
        },
        {
            name: 'Acciones',
            cell: row => (
                <div className="d-flex gap-2">
                    <button onClick={() => handleEditar(row)} className="btn btn-sm btn-outline-warning">
                        <LuPencilLine />
                    </button>
                    <button onClick={() => handleEliminar(row.id_ffw)} className="btn btn-sm btn-outline-danger">
                        <IoTrashOutline />
                    </button>
                </div>
            ),
        },
    ]

    const handleAgregar = () => {
        setShowAgregar(true);
    }

    const handleEditar = (ffw) => {
        setSelected(ffw);
        setShowEditar(true);
    }

    const handleCloseEditar = () => {
        setSelected(null);
        setShowEditar(false);
    }

    const handleEliminar = (idAEliminar) => {
        const token = localStorage.getItem('token'); // Obtener token de autenticación
        fetch(`https://api.dvstarlab.com/deleteffw/${idAEliminar}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.ok) {
                alert('Datos Eliminados Correctamente.');
                fetchData();
            } else {
                alert('Error al intentar eliminar datos.');
            }
        })
        .catch(error => {
            console.error('Error de red:', error);
        });
    }

    return (
        <div className='container-fluid'>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title' style={{ textAlign: 'center' }}>Freight Forwarders</h3>
                </div>
                <div className='card-body mb-2'>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className='form-control'
                        />
                    </div>
                    <div className="input-group mb-3">
                        <button type="button" className="btn btn-outline-primary" onClick={handleAgregar}>Agregar FFW</button>
                    </div>
                    {showAgregar && <AgregarFFW cerrar={()=>setShowAgregar(false)} />}
                    <div className='card'>
                        <div className='card-body'>
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                noHeader
                                responsive
                                pagination
                                highlightOnHover
                            />
                        </div>
                    </div>
                    {selected && showEditar && (
                        <EditarFFW
                            ffw={selected}
                            cerrar={handleCloseEditar}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
export default FFW