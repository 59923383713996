import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

const EditarProveedor = ({ cerrar, opciones, proveedor }) => {
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);
    const [nombre, setNombre] = useState(proveedor.nombre || '');
    const [tax, setTax] = useState(proveedor.tax || '');
    const [direccion, setDireccion] = useState(proveedor.direccion || '');
    const [ciudad, setCiudad] = useState(proveedor.ciudad || '');
    const [pais, setPais] = useState(proveedor.pais || '');
    const [contactoNombres, setContactoNombres] = useState(proveedor.contacto || []);
    const [contactoRoles, setContactoRoles] = useState(proveedor.rol || []);
    const [contactoCorreos, setContactoCorreos] = useState(proveedor.correo || []);
    const [contactoTelefonos, setContactoTelefonos] = useState(proveedor.telefono || []);
    const [nuevoContacto, setNuevoContacto] = useState({ nombre: '', rol: '', correo: '', telefono: '' });
    const [siglas, setSiglas] = useState(proveedor.siglas || '');
    const [giro, setGiro] = useState(proveedor.giro || '');
    const [estado, setEstado] = useState(proveedor.estado || '');
    const [codigo_postal, setCodigoPostal] = useState(proveedor.codigo_postal || '');
    const [productos, setProductos] = useState(proveedor.productos || []);
    const [nuevoProducto, setNuevoProducto] = useState(null);
    const [incoterm, setIncoterm] = useState(proveedor.incoterm || '');
    const [puerto, setPuerto] = useState(proveedor.puerto || '');
    const [almacen, setAlmacen] = useState(proveedor.almacen || '');
    const [calidades, setCalidades] = useState(proveedor.calidades || []);
    const [nuevaCalidad, setNuevaCalidad] = useState(null);
    const [condicion_pago, setCondicionPago] = useState(proveedor.condicion_pago || '');
    const [requisitos_calidad, setRequisitosCalidad] = useState(proveedor.requisitos_calidad || '');
    const [requisitos_etiquetado, setRequisitosEtiquetado] = useState(proveedor.requisitos_etiquetado || '');
    const [informacion_adicional, setInformacionAdicional] = useState(proveedor.informacion_adicional || '');
    const [correlativo, setCorrelativo] = useState(proveedor.correlativo || 0);
    const [modelo, setModelo] = useState(proveedor.modelo || null);

    const handleSubmit = (event) => {
        event.preventDefault();
        setBotonDeshabilitado(true);

        const updatedProveedor = {
            nombre,
            tax,
            direccion,
            ciudad,
            pais,
            contacto: contactoNombres,
            rol: contactoRoles,
            correo: contactoCorreos,
            telefono: contactoTelefonos,
            productos,
            siglas,
            giro,
            estado,
            codigo_postal,
            incoterm,
            almacen,
            puerto,
            calidades,
            condicion_pago,
            requisitos_calidad,
            requisitos_etiquetado,
            informacion_adicional,
            correlativo: parseInt(correlativo),
            modelo,
        };

        const token = localStorage.getItem('token');

        fetch(`https://api.dvstarlab.com/editarproveedor/${proveedor.id_proveedor}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(updatedProveedor),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error al editar proveedor');
                }
                return response.json();
            })
            .then(data => {
                console.log('Proveedor editado:', data);
                alert('Proveedor editado exitosamente');
                window.location.reload();
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally(() => {
                setBotonDeshabilitado(false);
            });
    };

    const handleAgregarProducto = () => {
        if (nuevoProducto) {
            setProductos([...productos, parseInt(nuevoProducto)]);
            setNuevoProducto(null);
        }
    };

    const handleDeleteProducto = (id) => {
        const updatedProductos = productos.filter((producto) => producto !== id);
        setProductos(updatedProductos);
    };

    const handleAgregarCalidad = () => {
        if (nuevaCalidad) {
            setCalidades([...calidades, parseInt(nuevaCalidad)]);
            setNuevaCalidad(null);
        }
    };

    const handleDeleteCalidad = (id) => {
        const updatedCalidades = calidades.filter((calidad) => calidad !== id);
        setCalidades(updatedCalidades);
    };

    const handleAgregarContacto = () => {
        if (nuevoContacto.nombre && nuevoContacto.rol && nuevoContacto.correo && nuevoContacto.telefono) {
            setContactoNombres([...contactoNombres, nuevoContacto.nombre]);
            setContactoRoles([...contactoRoles, nuevoContacto.rol]);
            setContactoCorreos([...contactoCorreos, nuevoContacto.correo]);
            setContactoTelefonos([...contactoTelefonos, nuevoContacto.telefono]);
            setNuevoContacto({ nombre: '', rol: '', correo: '', telefono: '' });
        } else {
            alert('Todos los campos del contacto son requeridos.');
        }
    };

    const handleDeleteContacto = (index) => {
        setContactoNombres(contactoNombres.filter((_, i) => i !== index));
        setContactoRoles(contactoRoles.filter((_, i) => i !== index));
        setContactoCorreos(contactoCorreos.filter((_, i) => i !== index));
        setContactoTelefonos(contactoTelefonos.filter((_, i) => i !== index));
    };

    const columnsProductos = [
        {
            name: 'ID Producto',
            selector: (row) => row
        },
        {
            name: 'Producto',
            selector: row => {
                if (row) {
                    const productoEncontrado = opciones.productos.find(producto => parseInt(producto.id_producto) === parseInt(row));
                    if (productoEncontrado) {
                        return `${productoEncontrado.nombre}`;
                    }
                }
                return 'Producto No Encontrado';
            },
            width: 'flex',
        },
        {
            name: 'Espesor',
            selector: row => {
                if (row) {
                    const productoEncontrado = opciones.productos.find(producto => parseInt(producto.id_producto) === parseInt(row));
                    if (productoEncontrado) {
                        return `${productoEncontrado.espesor}`;
                    }
                }
                return 'Espesor No Encontrado';
            },
        },
        {
            name: 'Eliminar',
            cell: row => (
                <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => {
                        if (window.confirm('¿Estás seguro?')) {
                            handleDeleteProducto(row);
                        }
                    }}
                >
                    X
                </button>
            ),
            ignoreRowClick: true,
        },
    ];

    const columnsCalidades = [
        {
            name: 'ID Calidad',
            selector: (row) => row
        },
        {
            name: 'Calidad',
            selector: row => {
                if (row) {
                    const calidadEncontrada = opciones.calidades.find(calidad => parseInt(calidad.id_calidad) === parseInt(row));
                    if (calidadEncontrada) {
                        return `${calidadEncontrada.calidad}`;
                    }
                }
                return 'Calidad No Encontrada';
            },
            width: 'flex',
        },
        {
            name: 'Eliminar',
            cell: row => (
                <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => {
                        if (window.confirm('¿Estás seguro?')) {
                            handleDeleteCalidad(row);
                        }
                    }}
                >
                    X
                </button>
            ),
            ignoreRowClick: true,
        },
    ];

    return (
        <div className='modal-overlay'>
            <div className='modal1'>
                <div className='card'>
                    <form onSubmit={handleSubmit}>
                        <div className='card-header'>
                            <h3 className='card-title' style={{ textAlign: 'center' }}>Editar Proveedor</h3>
                        </div>
                        <div className='card-body'>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese Nombre"
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Tax</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese Tax"
                                    value={tax}
                                    onChange={(e) => setTax(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Siglas</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese Siglas"
                                    value={siglas}
                                    onChange={(e) => setSiglas(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Dirección</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese Dirección"
                                    value={direccion}
                                    onChange={(e) => setDireccion(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Ciudad</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese Ciudad"
                                    value={ciudad}
                                    onChange={(e) => setCiudad(e.target.value)}
                                    required
                                />
                                <span className='input-group-text'>País</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese País"
                                    value={pais}
                                    onChange={(e) => setPais(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Nombre Contacto</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese nombre contacto"
                                    value={nuevoContacto.nombre}
                                    onChange={(e) => setNuevoContacto({ ...nuevoContacto, nombre: e.target.value })}
                                />
                                <span className='input-group-text'>Rol</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese rol contacto"
                                    value={nuevoContacto.rol}
                                    onChange={(e) => setNuevoContacto({ ...nuevoContacto, rol: e.target.value })}
                                />
                                <span className='input-group-text'>Correo</span>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Ingrese correo"
                                    value={nuevoContacto.correo}
                                    onChange={(e) => setNuevoContacto({ ...nuevoContacto, correo: e.target.value })}
                                />

                                <span className='input-group-text'>Teléfono</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese teléfono"
                                    value={nuevoContacto.telefono}
                                    onChange={(e) => setNuevoContacto({ ...nuevoContacto, telefono: e.target.value })}
                                />
                            </div>
                            <button type="button" className="btn btn-outline-primary" onClick={handleAgregarContacto}>
                                Agregar Contacto
                            </button>
                            <DataTable
                                columns={[
                                    { name: 'Nombre', selector: (_, index) => contactoNombres[index] },
                                    { name: 'Rol', selector: (_, index) => contactoRoles[index] },
                                    { name: 'Correo', selector: (_, index) => contactoCorreos[index] },
                                    { name: 'Teléfono', selector: (_, index) => contactoTelefonos[index] },
                                    {
                                        name: 'Eliminar',
                                        cell: (_, index) => (
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger"
                                                onClick={() => handleDeleteContacto(index)}
                                            >
                                                X
                                            </button>
                                        ),
                                        ignoreRowClick: true,
                                    },
                                ]}
                                data={contactoNombres.map((_, index) => index)}
                                highlightOnHover
                                dense
                                pagination={true}
                            />
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Estado</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese Estado"
                                    value={estado}
                                    onChange={(e) => setEstado(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Código Postal</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese código postal"
                                    value={codigo_postal}
                                    onChange={(e) => setCodigoPostal(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Puerto</span>
                                <select
                                    className="form-control"
                                    value={puerto}
                                    onChange={(e) => setPuerto(e.target.value)}
                                    required
                                >
                                    <option value="">Seleccionar Puerto</option>
                                    {opciones.puertos.map(puerto => (
                                        <option key={puerto.id_puerto} value={puerto.id_puerto}>
                                            {puerto.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>IncoTerm</span>
                                <select
                                    className="form-control"
                                    value={incoterm}
                                    onChange={(e) => setIncoterm(e.target.value)}
                                    required
                                >
                                    <option value="">Seleccionar IncoTerm</option>
                                    {opciones.incoterms.map(incoterm => (
                                        <option key={incoterm.id_incoterm} value={incoterm.id_incoterm}>
                                            {incoterm.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Almacén</span>
                                <select
                                    className="form-control"
                                    value={almacen}
                                    onChange={(e) => setAlmacen(e.target.value)}
                                    required
                                >
                                    <option value="">Seleccionar Almacén</option>
                                    {opciones.almacenes.map(almacen => (
                                        <option key={almacen.id_almacen} value={almacen.id_almacen}>
                                            {almacen.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Condición de Pago</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingrese Condición de Pago"
                                    value={condicion_pago}
                                    onChange={(e) => setCondicionPago(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Requisitos de Calidad</span>
                                <textarea
                                    className="form-control"
                                    placeholder="Ingrese Requisitos de Calidad"
                                    value={requisitos_calidad}
                                    onChange={(e) => setRequisitosCalidad(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Requisitos de Etiquetado</span>
                                <textarea
                                    className="form-control"
                                    placeholder="Ingrese Requisitos de Etiquetado"
                                    value={requisitos_etiquetado}
                                    onChange={(e) => setRequisitosEtiquetado(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='input-group mb-3'>
                                <span className='input-group-text'>Información Adicional</span>
                                <textarea
                                    className="form-control"
                                    placeholder="Ingrese Información Adicional"
                                    value={informacion_adicional}
                                    onChange={(e) => setInformacionAdicional(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='mb-3'>
                                <h5>Productos</h5>
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Producto</span>
                                    <select
                                        value={nuevoProducto}
                                        onChange={e => setNuevoProducto(e.target.value)}
                                        className='form-control'
                                    >
                                        <option value="">Seleccionar Producto</option>
                                        {opciones.productos.map(producto => (
                                            <option key={producto.id_producto} value={producto.id_producto}>
                                                {producto.nombre} - {producto.espesor}
                                            </option>
                                        ))}
                                    </select>
                                    <button type="button" onClick={handleAgregarProducto} className="btn btn-outline-primary" disabled={!nuevoProducto}>
                                        Agregar Producto
                                    </button>
                                </div>
                                <DataTable
                                    columns={columnsProductos}
                                    data={productos}
                                    highlightOnHover
                                    dense
                                    pagination={true}
                                />
                            </div>
                            <div className='mb-3'>
                                <h5>Calidades</h5>
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Calidad</span>
                                    <select
                                        value={nuevaCalidad}
                                        onChange={e => setNuevaCalidad(e.target.value)}
                                        className='form-control'
                                    >
                                        <option value="">Seleccionar Calidad</option>
                                        {opciones.calidades.map(calidad => (
                                            <option key={calidad.id_calidad} value={calidad.id_calidad}>
                                                {calidad.calidad}
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        type="button"
                                        onClick={handleAgregarCalidad}
                                        className="btn btn-outline-primary"
                                        disabled={!nuevaCalidad}
                                    >
                                        Agregar Calidad
                                    </button>
                                </div>
                                <DataTable
                                    columns={columnsCalidades}
                                    data={calidades}
                                    highlightOnHover
                                    dense
                                    pagination={true}
                                />
                                <div className='input-group mb-3'>
                                    <span className='input-group-text'>Correlativo OC</span>
                                    <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Ingrese correlativo ultima OC generada"
                                    value={correlativo}
                                    onChange={(e) => setCorrelativo(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='card-footer' style={{ textAlign: 'center' }}>
                            <button type="submit" className="btn btn-outline-success mb-3 mt-3" disabled={botonDeshabilitado}>Guardar</button>
                            <button type="button" className="btn btn-outline-danger mb-3 mt-3" onClick={cerrar}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditarProveedor;
