import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import DataTable from 'react-data-table-component';
/* import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css'; */
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

const workerUrl = `https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;

function SubirArchivos() {
    const [tipoArchivo, setTipoArchivo] = useState('');
    const [lecturaMasiva, setLecturaMasiva] = useState(null);
    const [modeloSeleccionado, setModeloSeleccionado] = useState(null);
    const [dataModelos, setDataModelos] = useState([]);
    const [mensaje, setMensaje] = useState('');
    const [archivoPDF, setArchivoPDF] = useState(null);
    const [archivosPDF, setArchivosPDF] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const inputFileRef = useRef(null);
    const [notaFiscalData, setNotaFiscalData] = useState({
        nro_nf: '', tipo_nf: '', llave: '', proveedor: '', emision: '', salida: '', 
        cnpj: '', valor_productos: '', valor_total: '', pkg: '', descuento: ''
    });
    const [nuevoDetalle, setNuevoDetalle] = useState({
        producto: '', calidad: '', medida: '', ncm: '', m3: '', usd_m3: '', descuento: '', total: ''
    });
    const [detalles, setDetalles] = useState([]);
    const zoomPluginInstance = zoomPlugin();
    const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
    const [totalFiles, setTotalFiles] = useState(0);
    const [uploadedCount, setUploadedCount] = useState(0);
    const [successfulUploads, setSuccessfulUploads] = useState([]);
    const [failedUploads, setFailedUploads] = useState([]);

    const handleChangeTipoArchivo = (e) => setTipoArchivo(e.target.value);

    const handleLecturaMasiva = (e) => setLecturaMasiva(parseInt(e.target.value, 10));

    const fetchModelos = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://api.dvstarlab.com/proveedores', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const modelos = await response.json();
            setDataModelos(modelos);
        } catch (error) {
            console.error('Error al obtener modelos:', error);
        }
    };

    useEffect(() => {
        if (lecturaMasiva === 2 || lecturaMasiva === 1 ) fetchModelos();
    }, [lecturaMasiva]);

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        if (lecturaMasiva === 1) {
            const file = files[0];
            if (file && file.type === 'application/pdf') setArchivoPDF(file);
        } else if (lecturaMasiva === 2) {
            setArchivosPDF(files.filter((file) => file.type === 'application/pdf'));
        }
    };

    const archivoPDFUrl = useMemo(() => {
        return archivoPDF ? URL.createObjectURL(archivoPDF) : null;
    }, [archivoPDF]);

    const lectorArchivos = async (files) => {
        setIsUploading(true);
        setTotalFiles(files.length);
        setUploadedCount(0);
        setSuccessfulUploads([]);
        setFailedUploads([]);
        const token = localStorage.getItem('token');
        if (!token) return alert("No se encontró un token de autenticación.");
        
        for (let i = 0; i < files.length; i++){
            const file = files[i];
            const formData = new FormData();
            formData.append('archivo', file); // Cambia 'archivos' a 'archivo'
            const proveedor = dataModelos.find(prov => prov.id_proveedor === parseInt(modeloSeleccionado));
            formData.append('modeloSeleccionado', proveedor.modelo);
            formData.append('proveedor', parseInt(proveedor.id_proveedor));
            try {
                const response = await fetch('https://api.dvstarlab.com/leerarchivo', {
                    method: "POST",
                    body: formData,
                });
    
                const data = await response.json();
    
                if (response.ok) {
                    setSuccessfulUploads(prev => [...prev, file.name]);
                } else {
                    setFailedUploads(prev => [...prev, file.name]);
                }
            } catch (error) {
                console.error(`Error al enviar el archivo ${file.name}:`, error);
                setFailedUploads(prev => [...prev, file.name]);
            } finally {
                setUploadedCount(prev => prev + 1);
            }
        }
        setIsUploading(false)
    };    

    const handleChangeModelo = (e) => {
        setModeloSeleccionado(e.target.value);
    };

    // Descartar un archivo PDF de la lista en lectura masiva
    const descartarArchivo = (index) => {
        const nuevosArchivos = archivosPDF.filter((_, i) => i !== index);
        setArchivosPDF(nuevosArchivos);
    };

    const handleNotaFiscalChange = (field, value) => setNotaFiscalData({ ...notaFiscalData, [field]: value });

    const Click = () => {
        inputFileRef.current.click(); // Simular el clic en el input para abrir el diálogo de selección
    };

    const handleNuevoDetalleChange = (field, value) => setNuevoDetalle({ ...nuevoDetalle, [field]: value });

    const agregarDetalle = () => {
        setDetalles([...detalles, nuevoDetalle]);
        setNuevoDetalle({
            producto: '', calidad: '', medida: '', ncm: '', m3: '', usd_m3: '', descuento: '', total: ''
        });
    };

    const columns = [
        {
            name: 'Nombre del Archivo',
            selector: (row) => row.name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Acción',
            cell: (row, index) => (
                <button className="btn btn-danger btn-sm" onClick={() => setArchivosPDF(archivosPDF.filter((_, i) => i !== index))}>
                    Descartar
                </button>
            ),
            right: true,
        },
    ];

    const columns2 = [
        { name: 'Producto', selector: row => row.producto, sortable: true },
        { name: 'Calidad', selector: row => row.calidad, sortable: true },
        { name: 'Medida', selector: row => row.medida, sortable: true },
        { name: 'NCM', selector: row => row.ncm, sortable: true },
        { name: 'm3', selector: row => row.m3, sortable: true },
        { name: 'USD/m3', selector: row => row.usd_m3, sortable: true },
        { name: 'Descuento', selector: row => row.descuento, sortable: true },
        { name: 'Total', selector: row => row.total, sortable: true },
        {
            name: 'Acción',
            cell: (row, index) => (
                <button className="btn btn-danger btn-sm" onClick={() => setDetalles(detalles.filter((_, i) => i !== index))}>
                    Eliminar
                </button>
            ),
            right: true,
        },
    ];

    const subidaManual = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                alert('No se encontró un token de autenticación.');
                return;
            }
    
            const response = await fetch('https://api.dvstarlab.com/agregarnotafiscalcondetalles', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    id_proveedor: notaFiscalData.proveedor, // Asegúrate de que este campo sea el id del proveedor
                    tipo_nf: notaFiscalData.tipo_nf,
                    nro_nf: notaFiscalData.nro_nf,
                    llave: notaFiscalData.llave,
                    emision: notaFiscalData.emision,
                    salida: notaFiscalData.salida,
                    cnpj: notaFiscalData.cnpj,
                    valor_productos: notaFiscalData.valor_productos,
                    valor_total: notaFiscalData.valor_total,
                    pkg: notaFiscalData.pkg,
                    descuento: notaFiscalData.descuento,
                    detalles: detalles, // Esta es la lista de detalles que has acumulado
                }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                alert('Nota Fiscal y detalles guardados exitosamente.');
                // Opcional: resetear los datos del formulario y los detalles
                setNotaFiscalData({
                    nro_nf: '', tipo_nf: '', llave: '', proveedor: '', emision: '', salida: '', 
                    cnpj: '', valor_productos: '', valor_total: '', pkg: '', descuento: ''
                });
                setDetalles([]);
                setArchivoPDF(null);
            } else {
                alert(`Error al guardar la Nota Fiscal: ${data.error || 'Error desconocido'}`);
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
            alert('Error en la solicitud. Por favor, intenta nuevamente.');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (lecturaMasiva === 2){
            lectorArchivos(archivosPDF);
        } else if (lecturaMasiva === 1){
            subidaManual();
        }
    };

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title" style={{ textAlign: 'center' }}>Subir Archivos para Lectura con IA</h3>
                </div>

                <div className="card-body mb-2">
                    <div className="input-group mb-3">
                        <label htmlFor="tipoArchivo" className="input-group-text" style={{ width: '170px' }}>Tipo de Archivo:</label>
                        <select id="tipoArchivo" className="form-control" onChange={handleChangeTipoArchivo} value={tipoArchivo}>
                            <option value="">Selecciona un tipo de archivo...</option>
                            <option value="Nota Fiscal">Nota Fiscal</option>
                        </select>
                    </div>
                </div>
                {/* Paso 2: Lectura masiva o archivo único */}
                {tipoArchivo && (
                    <div className="card-body mb-2">
                        <div className="input-group mb-3">
                            <label htmlFor="lecturaMasiva" className="input-group-text" style={{ width: '170px', marginRight: '0' }}>Tipo de Lectura:</label>
                            <select id="lecturaMasiva" className="form-control" onChange={handleLecturaMasiva} value={lecturaMasiva}>
                                <option value="">Selecciona el tipo de lectura...</option>
                                <option value={1}>Lectura de Archivo Único (Manual)</option>
                                <option value={2}>Lectura Masiva (Carpeta Completa)</option>
                            </select>
                        </div>
                    </div>
                )}
                {lecturaMasiva === 2 && (
                    <div className="card-body mb-2">
                        <div className="input-group mb-3">
                            <label htmlFor="modelo" className="input-group-text" style={{ width: '170px', marginRight: '0' }}>Seleccionar Modelo:</label>
                            <select id="modelo" className="form-control" onChange={handleChangeModelo} value={modeloSeleccionado}>
                                <option value="">Selecciona un modelo...</option>
                                {dataModelos.map((modelo, index) => (
                                    modelo.modelo && (
                                        <option key={index} value={modelo.id_proveedor}>{modelo.modelo}</option>
                                    )
                                ))}
                            </select>
                        </div>
                    </div>
                )}

                {/* Seleccionar archivo o carpeta */}
                {(lecturaMasiva === 1 || lecturaMasiva === 2) && (
                    <div className="card-body mb-2">
                        <div className="input-group">
                            <button className="btn btn-primary" onClick={Click}>
                                {lecturaMasiva === 2 ? 'Seleccionar Carpeta' :  'Seleccionar Archivo'}
                            </button>
                            <input
                                ref={inputFileRef}
                                type="file"
                                style={{ display: 'none' }}
                                multiple={lecturaMasiva === 2} // Permitir selección múltiple para lectura masiva
                                webkitdirectory={lecturaMasiva === 2 ? "true" : undefined} // Selección de carpeta
                                onChange={handleFileChange}
                            />
                        </div>
                        {!isUploading && uploadedCount > 0 && (
                            <div>
                                <h4>Resultados de la subida:</h4>
                                <p>Archivos subidos correctamente:</p>
                                <ul>
                                    {successfulUploads.map((file, index) => (
                                        <li key={index}>{file}</li>
                                    ))}
                                </ul>
                                <p>Archivos que fallaron al subir:</p>
                                <ul>
                                    {failedUploads.map((file, index) => (
                                        <li key={index}>{file}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )}

                {/* Si es lectura masiva, mostrar el DataTable */}
                {lecturaMasiva === 2 && archivosPDF.length > 0 && modeloSeleccionado && (
                    <div className="card-body">
                        <DataTable
                            title="Archivos PDF Seleccionados"
                            columns={columns}
                            data={archivosPDF}
                            pagination
                            highlightOnHover
                            striped
                        />
                    </div>
                )}

                {/* Si se selecciona un PDF único, mostrar el visualizador y el formulario */}
                {lecturaMasiva === 1 && archivoPDF && (
                    <div className="row mt-3 mb-3 p-3">
                        <div className="col-md-6 mb-4">
                            <Worker workerUrl={workerUrl}>
                                <div style={{ display: 'flex', flexDirection: 'column', height: '750px' }}>
                                    <div style={{ padding: '0.5rem', display: 'flex', alignItems: 'center' }}>
                                        <ZoomOutButton />
                                        <ZoomPopover />
                                        <ZoomInButton />
                                    </div>
                                    <div style={{ flex: 1, overflow: 'hidden' }}>
                                        <Viewer
                                            fileUrl={archivoPDFUrl}
                                            plugins={[zoomPluginInstance]}
                                        />
                                    </div>
                                </div>
                            </Worker>
                        </div>
                        <div className="col-md-6">
                            <form className="p-3">
                                <div className="mb-3">
                                    <label htmlFor="nro_nf" className="form-label">Número de Nota Fiscal</label>
                                    <input type="text" className="form-control" id="nro_nf" required value={notaFiscalData.nro_nf} onChange={(e) => handleNotaFiscalChange('nro_nf', e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="tipo_nf" className="form-label">Tipo de Nota Fiscal</label>
                                    <input type="text" className="form-control" id="tipo_nf" required value={notaFiscalData.tipo_nf} onChange={(e) => handleNotaFiscalChange('tipo_nf', e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="llave" className="form-label">Llave</label>
                                    <input type="text" className="form-control" id="llave" required value={notaFiscalData.llave} onChange={(e) => handleNotaFiscalChange('llave', e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="proveedor" className="form-label">Proveedor</label>
                                    <select id="proveedor" className="form-control"  value={notaFiscalData.proveedor} onChange={(e) => handleNotaFiscalChange('proveedor', e.target.value)}>
                                        <option value="">Selecciona un proveedor...</option>
                                        {dataModelos.map((modelo, index) => (
                                            <option key={index} value={modelo.id_proveedor}>{modelo.nombre}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="emision" className="form-label">Fecha de Emisión</label>
                                        <input type="date" className="form-control" id="emision" required value={notaFiscalData.emision} onChange={(e) => handleNotaFiscalChange('emision', e.target.value)} />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="salida" className="form-label">Fecha de Salida</label>
                                        <input type="date" className="form-control" id="salida" required value={notaFiscalData.salida} onChange={(e) => handleNotaFiscalChange('salida', e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <label htmlFor="valor_productos" className="form-label">Valor de Productos</label>
                                        <input type="number" className="form-control" id="valor_productos" required value={notaFiscalData.valor_productos} onChange={(e) => handleNotaFiscalChange('valor_productos', e.target.value)} />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="valor_total" className="form-label">Valor Total</label>
                                        <input type="number" className="form-control" id="valor_total" required value={notaFiscalData.valor_total} onChange={(e) => handleNotaFiscalChange('valor_total', e.target.value)} />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="pkg" className="form-label">Cantidad de Paquetes</label>
                                    <input type="text" className="form-control" id="pkg" required value={notaFiscalData.pkg} onChange={(e) => handleNotaFiscalChange('pkg', e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="descuento" className="form-label">Descuento</label>
                                    <input type="text" className="form-control" id="descuento" required value={notaFiscalData.descuento} onChange={(e) => handleNotaFiscalChange('descuento', e.target.value)} />
                                </div>

                                <h5 className="mt-4">Agregar Detalle</h5>
                                <div className="row">
                                    <div className="col-md-3">
                                        <input type="text" className="form-control" placeholder="Producto" value={nuevoDetalle.producto} onChange={(e) => handleNuevoDetalleChange('producto', e.target.value)} required />
                                    </div>
                                    <div className="col-md-3">
                                        <input type="text" className="form-control" placeholder="Calidad" value={nuevoDetalle.calidad} onChange={(e) => handleNuevoDetalleChange('calidad', e.target.value)} required />
                                    </div>
                                    <div className="col-md-3">
                                        <input type="text" className="form-control" placeholder="Medida" value={nuevoDetalle.medida} onChange={(e) => handleNuevoDetalleChange('medida', e.target.value)} required />
                                    </div>
                                    <div className="col-md-3">
                                        <input type="text" className="form-control" placeholder="NCM" value={nuevoDetalle.ncm} onChange={(e) => handleNuevoDetalleChange('ncm', e.target.value)} required />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-2">
                                        <input type="number" className="form-control" placeholder="m3" value={nuevoDetalle.m3} onChange={(e) => handleNuevoDetalleChange('m3', e.target.value)} required />
                                    </div>
                                    <div className="col-md-2">
                                        <input type="number" className="form-control" placeholder="USD/m3" value={nuevoDetalle.usd_m3} onChange={(e) => handleNuevoDetalleChange('usd_m3', e.target.value)} required />
                                    </div>
                                    <div className="col-md-2">
                                        <input type="number" className="form-control" placeholder="Descuento" value={nuevoDetalle.descuento} onChange={(e) => handleNuevoDetalleChange('descuento', e.target.value)} required />
                                    </div>
                                    <div className="col-md-2">
                                        <input type="number" className="form-control" placeholder="Total" value={nuevoDetalle.total} onChange={(e) => handleNuevoDetalleChange('total', e.target.value)} required />
                                    </div>
                                    <div className="col-md-3">
                                        <button type="button" className="btn btn-secondary" onClick={agregarDetalle}>
                                            Agregar Detalle
                                        </button>
                                    </div>
                                </div>

                                <DataTable columns={columns2} data={detalles} pagination highlightOnHover striped />
                            </form>
                        </div>
                    </div>
                )}

                {((lecturaMasiva === 2 && modeloSeleccionado && archivosPDF.length > 0) || (lecturaMasiva === 1 && archivoPDF)) && (
                    <div className="card-footer" style={{ textAlign: 'center' }}>
                        <button className="btn btn-success" onClick={handleSubmit}>
                            Subir Archivos
                        </button>
                    </div>
                )}
            </div>
            {/* Modal de carga */}
            <Modal show={isUploading} centered backdrop="static">
                <Modal.Body className="text-center">
                    <Spinner animation="border" role="status" />
                    <p>Subiendo archivos: {uploadedCount}/{totalFiles}</p>
                    <progress value={uploadedCount} max={totalFiles}></progress>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default SubirArchivos;
