import React, { useState } from 'react';

const AgregarSolicitud = ({ solicitudes, solicitudesInternas, puertos, ffw, carriers, cerrar }) => {
    const [formData, setFormData] = useState({
        codigo: '',
        id_ffw: '',
        id_carrier: '',
        id_puerto_origen: '',
        id_puerto_destino: '',
        ftorigen: '',
        ftdestino: '',
        nave: '',
        etd: '',
        cantidad: '',
        flete: '',
        estado: 0,
        requerimientos: '',
        cargo_weight: '',
        tipo_carga: '',
        id_solicitud_interna: '',
    });
    const [cantidadMaxima, setCantidadMaxima] = useState(null);
    const [solicitudInterna, setSolicitudInterna] = useState(null);
    const [pedidoCompletado, setPedidoCompletado] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleChangeUpperCase = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.toUpperCase() });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.codigo || !formData.id_puerto_origen || !formData.id_puerto_destino || !formData.etd || !formData.cantidad || !formData.flete) {
            alert('Por favor, complete todos los campos obligatorios.');
            return;
        }

        if(formData.id_solicitud_interna === '') {
            formData.id_solicitud_interna = null;
        }

        const token = localStorage.getItem('token');

        try {
            const response = await fetch('https://api.dvstarlab.com/addsolicitudbooking', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(formData)
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Error al agregar la solicitud');
            }
            alert('Solicitud agregada exitosamente');
            if(pedidoCompletado && response.ok){
                const responseSolicitudInterna = await fetch(`https://api.dvstarlab.com/editsolicitudinterna/${solicitudInterna.id_solicitudinterna}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ ...solicitudInterna, estado: 1 })
                });
                if (!responseSolicitudInterna.ok) {
                    const errorData = await responseSolicitudInterna.json();
                    throw new Error(errorData.error || 'Error al editar la solicitud interna');
                }
                alert('Solicitud Interna completada exitosamente');
            }else if(pedidoCompletado && !response.ok){
                alert('No se pudo completar la solicitud interna modificar manualmente');
            }
            cerrar();
        } catch (error) {
            console.error('Error:', error);
            alert('No se pudo agregar la solicitud');
        }
    };

    const handleChangeLimited = (e, max) => {
        const { name, value } = e.target;
        const numericValue = parseInt(value, 10);
    
        if (isNaN(numericValue) || numericValue <= 0) {
            alert('Por favor, ingrese un número válido mayor a 0.');
            return;
        }
    
        if (numericValue <= max) {
            setFormData({ ...formData, [name]: numericValue });
        } else {
            alert(`El valor máximo permitido es ${max} días.`);
        }
    };

    const handleChangeSolicitudInterna = (e) => {
        const { name, value } = e.target;
        const solicitud_interna = solicitudesInternas.find((solicitud) => solicitud.id_solicitudinterna === parseInt(value, 10));
        if(solicitud_interna){
            setSolicitudInterna(solicitud_interna);
            const solicitudesAsociadas = solicitudes.filter((solicitud) => solicitud.solicitud_interna.id === solicitud_interna.id_solicitudinterna);
            setCantidadMaxima(solicitud_interna.cantidad - (solicitudesAsociadas.reduce((acc, solicitud) => acc + solicitud.cantidad, 0)));
            setFormData({ ...formData, [name]: value, etd: solicitud_interna.etd, id_puerto_destino: solicitud_interna.id_puerto_destino });
        }
    }

    const handleChangeCantidad = (e) => {
        const { name, value } = e.target;
        if(solicitudInterna && cantidadMaxima){
            if(parseInt(value, 10) <= cantidadMaxima){
                setFormData({ ...formData, [name]: value });
                if(cantidadMaxima - parseInt(value, 10) === 0){
                    setPedidoCompletado(true);
                }
                if(cantidadMaxima - parseInt(value, 10) > 0){
                    setPedidoCompletado(false);
                }
            } else {
                alert(`La cantidad máxima permitida es ${cantidadMaxima}`);
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }

    return (
        <div className="modal show" style={{ display: 'block' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Agregar Solicitud de Booking</h5>
                        <button type="button" className="btn-close" onClick={cerrar}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Solicitud Interna</label>
                                <select
                                    className="form-select"
                                    name="id_solicitud_interna"
                                    onChange={handleChangeSolicitudInterna}
                                    value={formData.id_solicitud_interna}
                                >
                                    <option value="">Sin Solicitud Interna</option>
                                    {solicitudesInternas.map((item) => {
                                        if(item.estado !== 1){
                                            return (
                                                <option key={item.id_solicitudinterna} value={item.id_solicitudinterna}>
                                                    {item.codigo} - {item.cantidad} contenedores
                                                </option>
                                            );
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Código</label>
                                <input type="text" className="form-control" name="codigo" onChange={handleChangeUpperCase} value={formData.codigo} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">FFW</label>
                                <select
                                    className="form-select"
                                    name="id_ffw"
                                    onChange={(e) => handleChange({ target: { name: "id_ffw", value: e.target.value || null } })}
                                    value={formData.id_ffw || ""}
                                >
                                    <option value="">Sin FFW</option>
                                    {ffw.map((item) => (
                                        <option key={item.id_ffw} value={item.id_ffw}>
                                            {item.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {formData.id_ffw === null || formData.id_ffw === "" ? (
                                <div className="mb-3">
                                    <label className="form-label">Carrier</label>
                                    <select
                                        className="form-select"
                                        name="id_carrier"
                                        onChange={handleChange}
                                        value={formData.id_carrier}
                                    >
                                        <option value="">Seleccione un Carrier</option>
                                        {carriers
                                            .filter((carrier) => carrier.tipo === 2) // Filtra los carriers tipo 2
                                            .map((item) => (
                                                <option key={item.id_carrier} value={item.id_carrier}>
                                                    {item.nombre}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            ) : (
                                <div className="mb-3">
                                    <label className="form-label">Carrier</label>
                                    <select
                                        className="form-select"
                                        name="id_carrier"
                                        onChange={handleChange}
                                        value={formData.id_carrier}
                                    >
                                        <option value="">Seleccione un Carrier</option>
                                        {carriers
                                            .filter((carrier) => carrier.tipo === 1) // Filtra los carriers tipo 1
                                            .map((item) => (
                                                <option key={item.id_carrier} value={item.id_carrier}>
                                                    {item.nombre}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            )}
                            <div className="mb-3">
                                <label className="form-label">Puerto Origen</label>
                                <select
                                    className="form-select"
                                    name="id_puerto_origen"
                                    onChange={handleChange}
                                    value={formData.id_puerto_origen}
                                >
                                    <option value="">Seleccione un Puerto</option>
                                    {puertos
                                        .filter((puerto) => puerto.indicador === 1 || puerto.indicador === 3) // Filtra puertos origen
                                        .map((item) => (
                                            <option key={item.id_puerto} value={item.id_puerto}>
                                                {item.nombre}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Puerto Destino</label>
                                <select
                                    className="form-select"
                                    name="id_puerto_destino"
                                    onChange={handleChange}
                                    value={formData.id_puerto_destino}
                                >
                                    <option value="">Seleccione un Puerto</option>
                                    {puertos
                                        .filter((puerto) => puerto.indicador === 2 || puerto.indicador === 3) // Filtra puertos destino
                                        .map((item) => (
                                            <option key={item.id_puerto} value={item.id_puerto}>
                                                {item.nombre}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Free Time Origen</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="ftorigen"
                                    onChange={(e) => handleChangeLimited(e, 35)}
                                    value={formData.ftorigen}
                                    placeholder="Días"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Free Time Destino</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="ftdestino"
                                    onChange={(e) => handleChangeLimited(e, 35)}
                                    value={formData.ftdestino}
                                    placeholder="Días"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Nave</label>
                                <input type="text" className="form-control" name="nave" onChange={handleChange} value={formData.nave} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">ETD</label>
                                <input type="date" className="form-control" name="etd" onChange={handleChange} value={formData.etd} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Tipo de Carga</label>
                                <select className="form-select" name="tipo_carga" onChange={handleChange} value={formData.tipo_carga}>
                                    <option value="">Seleccione un tipo de carga</option>
                                    <option value="40HC Supertestado">40HC Supertestado</option>
                                </select>
                            </div>
                            {solicitudInterna && cantidadMaxima &&(
                                <div className="mb-3">
                                    <label className="form-label">Cantidad Máxima</label>
                                    <input type="number" className="form-control" value={cantidadMaxima} disabled />
                                </div>
                            )}
                            <div className="mb-3">
                                <label className="form-label">Cantidad de Contenedores</label>
                                <input type="number" className="form-control" name="cantidad" onChange={handleChangeCantidad} value={formData.cantidad} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Peso de Carga</label>
                                <input type='text' className="form-control" name="cargo_weight" onChange={handleChange} value={formData.cargo_weight} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Flete</label>
                                <input type="number" step="0.01" className="form-control" name="flete" onChange={handleChange} value={formData.flete} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Requerimientos</label>
                                <textarea className="form-control" name="requerimientos" onChange={handleChange} value={formData.requerimientos}></textarea>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Guardar</button>
                                <button type="button" className="btn btn-secondary" onClick={cerrar}>Cancelar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgregarSolicitud;
