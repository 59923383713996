import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './vistas/Home';
import About from './vistas/About';
import Contact from './vistas/Contact';
import Usuarios from './vistas/usuarios/Usuarios';
import Navigation from './modulos/Navbar/Nav';
import Menu from './modulos/Menu/Menu';
import Login from './vistas/Login';
import Almacenes from './vistas/almacenes/Almacenes';
import Calidades from './vistas/calidades/Calidades';
import Categorias from './vistas/categorias/Categorias';
import Especies from './vistas/especies/Especies';
import IncoTerms from './vistas/incoterms/IncoTerms';
import Medidas from './vistas/medidas/Medidas';
import Productos from './vistas/productos/Productos';
import Puertos from './vistas/puertos/Puertos';
import SubClientes from './vistas/subclientes/SubClientes';
import Documentos from './vistas/documentos/Documentos';
import Clientes from './vistas/clientes/Clientes';
import Proveedores from './vistas/proveedores/Proveedores';
import CartasCredito from './vistas/cartascredito/CartasCredito';
import LineasCredito from './vistas/lineascredito/LineasCredito';
import ShippingDocs from './vistas/shippingdocs/ShippingDocs';
import Proformas from './vistas/proformas/Proformas';
import OrdenesCompra from './vistas/ordenescompra/OrdenesCompra';
import PaymentTerms from './vistas/paymentterms/PaymentTerms';
import SubirArchivos from './vistas/lector/SubirArchivo';
import NotasFiscales from './vistas/notasfiscales/NotasFiscales';
import FFW from './vistas/freightforwarder/FFW';
import Carriers from './vistas/carriers/Carriers';
import SolicitudesBooking from './vistas/solicitudesbooking/SolicitudesBooking';
import SolicitudesBookingInternas from './vistas/solicitudesinternas/SolicitudesBookingInternas';

const menuItems = {
    home: [{ label: 'Home', href: '/home' }],
    file: [{ label: 'Cargar Archivos', href: '/file' }],
    generate: [
      { label: 'Ordenes Compra', href: '/ordenescompra' },
      { label: 'Proformas Invoice', href: '/proformas' },
    ],
    records: [
      { label: 'Almacenes', href: '/almacenes' },
      { label: 'Cartas de crédito', href: '/cartascredito' },
      { label: 'Clientes', href: '/clientes' },
      { label: 'IncoTerms', href: '/incoterms' },
      { label: 'Línea de Crédito', href: '/lineascredito' },
      { label: 'Notas Fiscales', href: '/notasfiscales' },
      { label: 'Productos', href: '#', subItems: [
          { label: 'Calidades', href: '/calidades' },
          { label: 'Categorias', href: '/categorias' },
          { label: 'Especies', href: '/especies' },
          { label: 'Medidas', href: '/medidas' },
          { label: 'Productos', href: '/productos' }
        ]},
      { label: 'Payment Terms', href: '/paymentterms' },
      { label: 'Proveedores', href: '/proveedores' },
      { label: 'Puertos', href: '/puertos' },
      { label: 'Documentos de Embarque', href: '/shippingdocs' },
      { label: 'Sub-Clientes', href: '/subclientes' },
      { label: 'Tipos de Documentos', href: '/documentos' },
      { label: 'Usuarios', href: '/usuarios' }
    ],
    purchases: [
      { label: 'Proveedores', href: '/proveedor' },
      { label: 'Notas Fiscales', href: '/notas' },
      { label: 'Productos x Notas Fiscales', href: '/productonf' },
    ],
    sales: [
      { label: 'Notas Fiscales', href: '/notasptb' },
      { label: 'Productos x Notas Fiscales', href: '/ptbnf' },
    ],
    dues: [{ label: 'DUE\'S', href: '/dues' }],
    invoices: [{ label: 'Invoices', href: '/invoices' }],
};
  
const getCurrentMenu = (pathname) => {
    if (pathname.startsWith('/home')) return menuItems.home;
    if (pathname.startsWith('/file')) return menuItems.file;
    if (pathname.startsWith('/ordenescompra') || pathname.startsWith('/proformas')) return menuItems.generate;
    if (pathname.startsWith('/almacenes') || pathname.startsWith('/cartascredito') || pathname.startsWith('/clientes') || pathname.startsWith('/incoterms') || pathname.startsWith('/lineascredito') || pathname.startsWith('/calidades') || pathname.startsWith('/categorias') || pathname.startsWith('/especies') || pathname.startsWith('/medidas') || pathname.startsWith('/productos') || pathname.startsWith('/proveedores') || pathname.startsWith('/puertos') || pathname.startsWith('/shippingdocs') || pathname.startsWith('/subclientes') || pathname.startsWith('/documentos') || pathname.startsWith('/paymentterms') || pathname.startsWith('/usuarios')) return menuItems.records;
    if (pathname.startsWith('/proveedor') || pathname.startsWith('/notas') || pathname.startsWith('/productonf')) return menuItems.purchases;
    if (pathname.startsWith('/notasptb') || pathname.startsWith('/ptbnf')) return menuItems.sales;
    if (pathname.startsWith('/dues')) return menuItems.dues;
    if (pathname.startsWith('/invoices')) return menuItems.invoices;
    return [];
};

const AppContent = () => {
    const [menuExpanded, setMenuExpanded] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState([]);
    const location = useLocation();

    useEffect(() => {
        setSelectedMenu(getCurrentMenu(location.pathname));
      }, [location.pathname]);
    
    const handleHoverChange = (isExpanded) => {
      setMenuExpanded(isExpanded);
    };

    return(
        <div className='erp'>
            {localStorage.loggedIn && 
                <div>
                    <Navigation onToggleMenu={handleHoverChange} selectedMenu={selectedMenu} />
                </div>
            }
            <div className='app-container' style={{ marginLeft: menuExpanded ? '250px' : '70px', transition: 'margin-left 0.3s' }}>
                {localStorage.loggedIn && 
                    <Menu onHoverChange={handleHoverChange} />
                }
                <div className='content-container'>
                    <Routes>
                        <Route path="/home" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        {localStorage.loggedIn ? (
                            <>
                                <Route path="/" element={<Navigate to="/home" replace />} />
                                <Route path='/file' element={<SubirArchivos />} />
                                <Route path="/usuarios" element={<Usuarios />} />
                                <Route path="/almacenes" element={<Almacenes />} />
                                <Route path="/calidades" element={<Calidades />} />
                                <Route path="/categorias" element={<Categorias />} />
                                <Route path="/especies" element={<Especies />} />
                                <Route path="/incoterms" element={<IncoTerms />} />
                                <Route path="/medidas" element={<Medidas />} />
                                <Route path="/productos" element={<Productos />} />
                                <Route path="/puertos" element={<Puertos />} />
                                <Route path="/subclientes" element={<SubClientes />} />
                                <Route path="/documentos" element={<Documentos />} />
                                <Route path="/clientes" element={<Clientes />} />
                                <Route path="/proveedores" element={<Proveedores />} />
                                <Route path="/cartascredito" element={<CartasCredito />} />
                                <Route path="/lineascredito" element={<LineasCredito />} />
                                <Route path="/shippingdocs" element={<ShippingDocs />} />
                                <Route path="/proformas" element={<Proformas />} />
                                <Route path="/ordenescompra" element={<OrdenesCompra />} />
                                <Route path="/paymentterms" element={<PaymentTerms />} />
                                <Route path="/notasfiscales" element={<NotasFiscales />} />
                                <Route path="/carriers" element={<Carriers />} />
                                <Route path="/ffw" element={<FFW />} />
                                <Route path='/solicitudes' element={<SolicitudesBooking />} />
                                <Route path='/solicitudesinternas' element={<SolicitudesBookingInternas />} />
                            </>
                        ) : (
                            <>
                                <Route path="/" element={<Login />} />
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </>
                        )}
                    </Routes>
                </div>
            </div>
        </div>
    )
}

function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

export default App;
